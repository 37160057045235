import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* categoryListSaga({ payload }) {

    try {

        let response = yield call(api.categoryList, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiCatgoryListSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCategoryManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCategoryManagementFailed });
    }
}

function* categoryDeleteSaga({ payload }) {

    try {

        let response = yield call(api.deleteCategory, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: apiConstants.apiDeleteCatgorySuccess});
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });

            showMessage(appConstants.deleteCategoryMessage,"success-notification");
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCategoryManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCategoryManagementFailed });
    }
}


function* categoryAddSaga({ payload }) {

    try {

        let response = yield call(api.addCategory, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.categoryAddMessage,"success-notification");

            yield put({ type: apiConstants.apiAddCatgorySuccess});
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCategoryManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCategoryManagementFailed });
    }
}

function* categoryEditSaga({ payload }) {

    try {

        let response = yield call(api.editCategory, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.categoryUpdateMessage,"success-notification");

            yield put({ type: apiConstants.apiEditCatgorySuccess});
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCategoryManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCategoryManagementFailed });
    }
}

export default function* rootCategoryManagementSaga() {

    yield takeLatest(apiConstants.apiCatgoryListInitiate, categoryListSaga);
    yield takeLatest(apiConstants.apiDeleteCatgoryInitiate, categoryDeleteSaga);
    yield takeLatest(apiConstants.apiAddCatgoryInitiate, categoryAddSaga);
    yield takeLatest(apiConstants.apiEditCatgoryInitiate, categoryEditSaga);
}
