import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* cutomerListSaga({ payload }) {

    try {

        let response = yield call(api.customerList, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiCustomerListSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* cutomerListForCsvSaga({ payload }) {

    try {

        let response = yield call(api.customerList, payload);
        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiCustomerListForCsvSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* cutomerBlockSaga({ payload }) {

    try {

        let response = yield call(api.customerBlock, payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(response.message, "success-notification");
            yield put({ type: apiConstants.apiCustomerBlockSuccess, payload })
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}


function* cutomerDeleteSaga({ payload }) {

    try {

        let response = yield call(api.customerDelete, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(response.message, "success-notification");
            yield put({ type: apiConstants.apiCustomerDeleteSuccess })
            yield put({ type: apiConstants.apiCustomerListInitiate, payload: payload?.list });
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* addProviderSaga({ payload }) {

    try {

        let response = yield call(api.addProvider, payload?.payload);
        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.providerAddedMessage, "success-notification");
            yield put({ type: apiConstants.apiAddProviderSuccess });
            yield put({ type: apiConstants.apiCustomerListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* editProviderSaga({ payload }) {

    try {

        let response = yield call(api.editProvider, payload?.payload);
        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.providerEditMessage, "success-notification");
            yield put({ type: apiConstants.apiEditProviderSuccess });
            yield put({ type: apiConstants.apiCustomerListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* verifyProviderSaga({ payload }) {

    try {

        let response = yield call(api.verifyProvider, payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(response.message, "success-notification");
            yield put({ type: apiConstants.apiVerifyProviderSuccess, payload })
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}

function* importProviderSaga({ payload }) {

    try {

        let response = yield call(api.importProviderCsv, payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(response.message, "success-notification");
            yield put({ type: apiConstants.apiImportProviderSuccess });
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiCustomerManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiCustomerManagementFailed });
    }
}


export default function* rootCustomerManagementSaga() {

    yield takeLatest(apiConstants.apiCustomerListInitiate, cutomerListSaga);
    yield takeLatest(apiConstants.apiCustomerBlockInitiate, cutomerBlockSaga);
    yield takeLatest(apiConstants.apiCustomerDeleteInitiate, cutomerDeleteSaga);
    yield takeLatest(apiConstants.apiCustomerListForCsvInitiate, cutomerListForCsvSaga);
    yield takeLatest(apiConstants.apiAddProviderInitiate, addProviderSaga);
    yield takeLatest(apiConstants.apiEditProviderInitiate, editProviderSaga);
    yield takeLatest(apiConstants.apiVerifyProviderInitiate, verifyProviderSaga);
    yield takeLatest(apiConstants.apiImportProviderInitiate, importProviderSaga);

}
