import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Pagination, Select, Tabs, Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchAction, reportListAction } from "../../redux/action";
import moment from "moment";

const ReportManagement = () => {

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState("Feedback")
    const [offsetData, setOffsetData] = useState({ offset: 0, limit: 10 })
    const [currentPage, setCurrentPage] = useState(1)
    const [showViewModal, setViewModal] = useState(false)
    const [currentReport, setCurrentReport] = useState(null)

    const { searchValue } = useSelector(x => x.authentication)
    const { loading, reportListData } = useSelector(x => x.reportManagement)

    useEffect(() => {

        dispatch(handleSearchAction({ value: "" }))
    }, [])

    useEffect(() => {

        if (searchValue) dispatch(reportListAction({ offset: 0, limit: offsetData.limit, search: searchValue, type: activeTab }))

        else dispatch(reportListAction({ ...offsetData, search: searchValue, type: activeTab }))
    }, [activeTab, offsetData, searchValue])


    let prevOffset = ((currentPage - 1) * offsetData.limit) - offsetData.limit
    let nextOffset = ((currentPage + 1) * offsetData.limit) - offsetData.limit

    const columns1 = [
        {
            title: appConstants.customer,
            dataIndex: "user",
            key: "user",
            sorter: false,
            render: (value, item, index) => item?.user?.name || "N/A",
        },
        {
            title: appConstants.topic,
            dataIndex: "topic",
            key: "topic",
            sorter: false,
            render: (title, data, index) => (<>{data.topic || "N/A"}</>)
        },
        {
            title: appConstants.feedback,
            dataIndex: "description",
            key: "description",
            sorter: false,
            elepsis: true,
            render: (title, data, index) => (<div className="report-feedback-wrapper">{data.description || "N/A"}</div>)
        },
        {
            title: appConstants.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.show} alt="" onClick={() => {

                            setCurrentReport(data)
                            setViewModal(true)
                        }} />

                    </div>
                );
            },
        },
        {
            title: appConstants.date,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div>12/12/2023  11.50 AM</div>,
        },
    ];

    const columns2 = [
        {
            title: appConstants.customer,
            dataIndex: "user",
            key: "user",
            sorter: false,
            render: (value, item, index) => item?.user?.name || "N/A",
        },
        {
            title: appConstants.category,
            dataIndex: "category",
            key: "category",
            sorter: false,
            render: (title, data, index) => (<>{(data.category ? data.category : data.categoryId?.name) || "N/A"}</>)
        },
        {
            title: appConstants.subCategory,
            dataIndex: "service",
            key: "service",
            sorter: false,
            elepsis: true,
            render: (title, data, index) => (<>{data?.service || "N/A"}</>)
        },
        {
            title: appConstants.date,
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: false,
            render: (value, item, index) => <div>{moment(item.createdAt).format("DD/MM/YYYY hh:mm A")}</div>,
        },
    ];


    const tabItems = [
        {
            key: 'Feedback',
            label: <div className="tab-heading">{appConstants.feedback} <div className="tab-counter">{reportListData?.unseenFeedback || 0}</div></div>,
            children: <div>

                <Table

                    className="table-wrapper"
                    dataSource={reportListData?.list?.map((i, index) => ({ ...i, key: index }))}
                    columns={columns1}
                    showSorterTooltip={false}
                    pagination={false}
                    loading={loading}
                />

                <div className="pagination-wrapper">

                    <div className="select-wrapper">

                        Pages :

                        <Select

                            suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                            className="select"
                            defaultValue={10}
                            options={[
                                {
                                    value: 10,
                                    label: '10 Rows',
                                },
                                {
                                    value: 20,
                                    label: '20 Rows',
                                },
                                {
                                    value: 30,
                                    label: '30 Rows',
                                },
                            ]}
                            onChange={(e) => setOffsetData({ ...offsetData, limit: e })}
                        />
                    </div>


                    <Pagination

                        className="pagination"
                        current={currentPage}
                        total={reportListData?.pagination?.totalCount}
                        showLessItems={false}
                        onChange={(e) => {

                            setCurrentPage(e)
                            setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                        }}

                        pageSize={offsetData.limit}
                    />

                    <div className="action-wrapper" >

                        <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                            if (prevOffset >= 0) {
                                setOffsetData({ ...offsetData, offset: prevOffset })
                                setCurrentPage(currentPage - 1)
                            }
                        }}>

                            <ArrowLeftOutlined className="left-icon" />
                            {appConstants.previous}
                        </span>

                        <span className={nextOffset < (reportListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                            if (nextOffset < (reportListData?.pagination?.totalCount || 0)) {
                                setOffsetData({ ...offsetData, offset: nextOffset })
                                setCurrentPage(currentPage + 1)
                            }
                        }}>

                            {appConstants.next}
                            <ArrowRightOutlined className="right-icon" />

                        </span>
                    </div>
                </div>

            </div>,
        },
        {
            key: 'Requested category',
            label: <div className="tab-heading">{appConstants.request} <div className="tab-counter">{reportListData?.unseenCategory || 0}</div></div>,
            children: <div>

                <Table

                    className="table-wrapper"
                    dataSource={reportListData?.list?.map((i, index) => ({ ...i, key: index }))}
                    columns={columns2}
                    showSorterTooltip={false}
                    pagination={false}
                    loading={loading}

                />

                <div className="pagination-wrapper">

                    <div className="select-wrapper">

                        Pages :

                        <Select

                            suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                            className="select"
                            defaultValue={10}
                            options={[
                                {
                                    value: 10,
                                    label: '10 Rows',
                                },
                                {
                                    value: 20,
                                    label: '20 Rows',
                                },
                                {
                                    value: 30,
                                    label: '30 Rows',
                                },
                            ]}
                            onChange={(e) => setOffsetData({ ...offsetData, limit: e })}
                        />
                    </div>


                    <Pagination

                        className="pagination"
                        current={currentPage}
                        total={reportListData?.pagination?.totalCount}
                        showLessItems={false}
                        onChange={(e) => {

                            setCurrentPage(e)
                            setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                        }}

                        pageSize={offsetData.limit}
                    />

                    <div className="action-wrapper" >

                        <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                            if (prevOffset >= 0) {
                                setOffsetData({ ...offsetData, offset: prevOffset })
                                setCurrentPage(currentPage - 1)
                            }
                        }}>

                            <ArrowLeftOutlined className="left-icon" />
                            {appConstants.previous}
                        </span>

                        <span className={nextOffset < (reportListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                            if (nextOffset < (reportListData?.pagination?.totalCount || 0)) {
                                setOffsetData({ ...offsetData, offset: nextOffset })
                                setCurrentPage(currentPage + 1)
                            }
                        }}>

                            {appConstants.next}
                            <ArrowRightOutlined className="right-icon" />

                        </span>
                    </div>
                </div>
            </div>,
        }
    ]
    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.report}

                </div>

                <Breadcrumbs
                    path={["dashboard"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.report]}
                />
            </div>

            <Tabs onChange={e => setActiveTab(e)} className="tabs-wrapper" defaultActiveKey="1" items={tabItems} tabBarExtraContent={<button className="total-count-btn">{appConstants.total} {reportListData?.unseenCategory + reportListData.unseenFeedback}</button>} />

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showViewModal}
                footer={false}
                onCancel={() => setViewModal(false)}
            >
                <div className="header">

                    <div className="heading">
                        {appConstants.feedbackDetails}
                    </div>

                </div>

                <div className="personal-detail-wrapper">

                    <div className="detail-wrapper">

                        <div className="field-wrapper">

                            <div className="field">{appConstants.customer}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentReport?.user?.name || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.topic}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentReport?.topic || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.feedback}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentReport?.description || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.date}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentReport?.createdAt ? moment(currentReport.createdAt).format("DD/MM/YYYY hh:mm A") : "N/A"}</div>
                        </div>

                    </div>

                </div>

            </Modal>
        </div>
    )
}

export default ReportManagement;