import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Switch, Pagination, Select, Modal, Tabs, DatePicker } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "react";

const NotificationManagement = () => {

    const [showAddModal, setAddModal] = useState(false)
    const [textCount, setTextCount] = useState(0)

    const [modalVisible, setModalVisible] = useState(false)
    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)

    const data1 = [
        {
            customer: "John",
            topic: "Topic 1",
            blocked: false,
            key: 1,
            heading: "Carousel 1",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: true,
            key: 2,
            heading: "Carousel 2",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: false,
            key: 3,
            heading: "Carousel 3",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: true,
            key: 4,
            heading: "Onboarding screen 1",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: false,
            key: 5,
            heading: "Onboarding screen 2",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: true,
            key: 6,
            heading: "Onboarding screen 3",
            service: "Home Cleaning"
        },
        {
            customer: "John",
            topic: "Topic 1",
            blocked: false,
            key: 7,
            heading: "Onboarding screen 4",
            service: "Home Cleaning"
        },
    ]

    const columns1 = [
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div><Switch defaultChecked={item.blocked} /></div>,
        },
        {
            title: appConstants.heading,
            dataIndex: "heading",
            key: "heading",
            sorter: false,
            render: (value, item, index) => item.heading,
        },
        {
            title: appConstants?.url,
            dataIndex: "url",
            key: "url",
            sorter: false,
            render: (title, data, index) => <input className="url-input" placeholder="Link..." />
        },
        {
            title: appConstants.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.trash} alt="" onClick={() => showModal()} />

                    </div>
                );
            },
        },
        {
            title: appConstants.lastUpdated,
            dataIndex: "srno",
            key: "srno1",
            sorter: false,
            render: (value, item, index) => <div>12/12/2023  11.50 AM</div>,
        },
    ];

    const data2 = [
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 1,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 2,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 3,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 4,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 5,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 6,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 7,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
    ]

    const columns2 = [
        {
            title: appConstants.heading,
            dataIndex: "heading",
            key: "heading",
            sorter: false,
            render: (value, item, index) => item.heading,
        },
        {
            title: appConstants.content,
            dataIndex: "content",
            key: "content",
            sorter: false,
            render: (title, data, index) => (<div className="report-feedback-wrapper">{data.content || "N/A"}</div>)
        },
        {
            title: appConstants.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.trash} alt="" onClick={() => showModal()} />

                    </div>
                );
            },
        },
        {
            title: appConstants.time,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div>12/12/2023  11.50 AM</div>,
        },
    ];

    const data3 = [
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 1,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 2,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 3,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 4,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 5,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New provider",
            topic: "Topic 1",
            blocked: true,
            key: 6,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
        {
            heading: "New user",
            topic: "Topic 1",
            blocked: false,
            key: 7,
            category: "Home Service",
            content: "Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur. ..."
        },
    ]

    const columns3 = [
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div><Switch defaultChecked={item.blocked} /></div>,
        },
        {
            title: appConstants.heading,
            dataIndex: "heading",
            key: "heading",
            sorter: false,
            render: (value, item, index) => item.heading,
        },
        {
            title: appConstants.content,
            dataIndex: "content",
            key: "content",
            sorter: false,
            render: (title, data, index) => (<div className="report-feedback-wrapper">{data.content || "N/A"}</div>)
        },
        {
            title: appConstants.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon edit-icon" src={images.edit} alt="" onClick={() => setAddModal(true)} />
                        <img className="action-icon" src={images.trash} alt="" onClick={() => showModal()} />

                    </div>
                );
            },
        },
        {
            title: appConstants.lastUpdated,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div>12/12/2023  11.50 AM</div>,
        },
    ];


    const tabItems = [
        {
            key: '1',
            label: <div className="tab-heading">{appConstants.contentUpdates} <div className="tab-counter">35</div></div>,
            children: <div>

                <Table

                    className="table-wrapper"
                    dataSource={data1}
                    columns={columns1}
                    showSorterTooltip={false}
                    pagination={false}
                />

                <div className="pagination-wrapper">

                    <div className="select-wrapper">

                        Pages :

                        <Select

                            suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                            className="select"
                            defaultValue={10}
                            options={[
                                {
                                    value: 10,
                                    label: '10 Rows',
                                },
                                {
                                    value: 20,
                                    label: '20 Rows',
                                },
                                {
                                    value: 30,
                                    label: '30 Rows',
                                },
                            ]}
                        />
                    </div>


                    <Pagination

                        className="pagination"
                        defaultCurrent={6}
                        total={20}
                    />

                    <div className="action-wrapper">

                        <span className="action">

                            <ArrowLeftOutlined className="left-icon" />
                            {appConstants.previous}
                        </span>

                        <span className="action">

                            {appConstants.next}
                            <ArrowRightOutlined className="right-icon" />

                        </span>
                    </div>
                </div>

            </div>,
        },
        {
            key: '2',
            label: <div className="tab-heading">{appConstants.activityBasedUpdated} <div className="tab-counter">35</div></div>,
            children: <div>

                <Table

                    className="table-wrapper"
                    dataSource={data2}
                    columns={columns2}
                    showSorterTooltip={false}
                    pagination={false}
                />

                <div className="pagination-wrapper">

                    <div className="select-wrapper">

                        Pages :

                        <Select

                            suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                            className="select"
                            defaultValue={10}
                            options={[
                                {
                                    value: 10,
                                    label: '10 Rows',
                                },
                                {
                                    value: 20,
                                    label: '20 Rows',
                                },
                                {
                                    value: 30,
                                    label: '30 Rows',
                                },
                            ]}
                        />
                    </div>


                    <Pagination

                        className="pagination"
                        defaultCurrent={6}
                        total={20}
                    />

                    <div className="action-wrapper">

                        <span className="action">

                            <ArrowLeftOutlined className="left-icon" />
                            {appConstants.previous}
                        </span>

                        <span className="action">

                            {appConstants.next}
                            <ArrowRightOutlined className="right-icon" />

                        </span>
                    </div>
                </div>
            </div>,
        },
        {
            key: '3',
            label: <div className="tab-heading">{appConstants.broadcastNotification} <div className="tab-counter">35</div></div>,
            children: <div>

                <Table

                    className="table-wrapper"
                    dataSource={data3}
                    columns={columns3}
                    showSorterTooltip={false}
                    pagination={false}
                />

                <div className="pagination-wrapper">

                    <div className="select-wrapper">

                        Pages :

                        <Select

                            suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                            className="select"
                            defaultValue={10}
                            options={[
                                {
                                    value: 10,
                                    label: '10 Rows',
                                },
                                {
                                    value: 20,
                                    label: '20 Rows',
                                },
                                {
                                    value: 30,
                                    label: '30 Rows',
                                },
                            ]}
                        />
                    </div>


                    <Pagination

                        className="pagination"
                        defaultCurrent={6}
                        total={20}
                    />

                    <div className="action-wrapper">

                        <span className="action">

                            <ArrowLeftOutlined className="left-icon" />
                            {appConstants.previous}
                        </span>

                        <span className="action">

                            {appConstants.next}
                            <ArrowRightOutlined className="right-icon" />

                        </span>
                    </div>
                </div>
            </div>,
        }
    ]
    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.notification}

                </div>

                <Breadcrumbs
                    path={["dashboard"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.notification]}
                />
            </div>

            <div className="notification-header-wrapper">

                <div className="filter-wrapper">

                    <div className="select-wrapper">

                        <SearchOutlined className="search-icon" />
                        <Select
                            className="select"
                            placeholder="Type"
                            options={[
                                {
                                    value: "Type 1",
                                    label: 'Type 1',
                                },
                                {
                                    value: "Type 2",
                                    label: 'Type 2',
                                },
                                {
                                    value: "Type 3",
                                    label: 'Type 3',
                                },
                            ]}
                        />
                    </div>

                    <DatePicker className="date-picker" placeholder="From" suffixIcon={false} />
                    <DatePicker className="date-picker" placeholder="To" suffixIcon={false} />


                </div>

                <button className="add-notification" onClick={() => setAddModal(true)}>+ {appConstants.add}</button>

            </div>

            <Tabs className="tabs-wrapper" defaultActiveKey="1" items={tabItems} tabBarExtraContent={<button className="total-count-btn">{appConstants.total} 105</button>} />


            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        hideModal()
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={hideModal}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteNotificationWarning}
            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showAddModal}
                footer={false}
                onCancel={() => setAddModal(false)}
            >
                <div className="add-provider-wrapper">

                    <div className="fields-wrapper">
                        <label>{appConstants.heading}</label>
                        <input placeholder={appConstants.writeHeading} />

                    </div>

                    <div className="fields-wrapper">
                        <label>{appConstants.content}</label>
                        <textarea placeholder={appConstants.writeContent} maxLength={300} onChange={(e) => setTextCount(e.target.value.length)} />

                        <div className="input-text-count">{textCount}/300</div>

                    </div>


                    <div className="btn-wrapper">

                        <button className="cancel" onClick={() => setAddModal(false)}>{appConstants.cancel}</button>
                        <button className="submit" onClick={() => setAddModal(false)}>{appConstants.submit}</button>

                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default NotificationManagement;