import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { appConstants } from '../../utils/appConstants';
import { useFormik } from 'formik'
import { forgotPasswordSchema } from '../../utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPasswordAction } from '../../redux/action/index'
import { Spin } from 'antd';

const ForgotPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loading = useSelector(x => x.authentication.loading)
    const formik = useFormik({

        initialValues: { email: "" },
        onSubmit: (values) => dispatch(forgotPasswordAction(values, navigate)),
        validationSchema: forgotPasswordSchema
    })

    return (
        <div className="auth-wrapper forgot-password-wrapper">

            <form onSubmit={formik.handleSubmit}>

                <div className="form-content">

                    <div className="heading">
                        {appConstants.forgotPassword}
                    </div>

                    <span className="sub-heading">
                        {appConstants.forgotPasswordText}
                    </span>

                    <input

                        name="email"
                        onChange={(e) => {

                            if (e.target.value.startsWith(" ")) e.preventDefault()
                            else formik.handleChange(e)

                        }}
                        value={formik.values.email}
                        placeholder="e-mail address"
                        type="text"
                    />

                    {formik.errors.email && <div className="validation">{formik.errors.email}</div>}

                    <div className="switch-wrapper">

                    </div>

                    <div className="button-wrapper">
                        <button disabled={loading} type="submit">{loading ? <Spin /> : appConstants.continue}</button>
                    </div>

                </div>
            </form>

            <div className="footer">© {moment().format("YYYY")},{appConstants.authCopyRightText}</div>

        </div>
    )
}

export default ForgotPassword;