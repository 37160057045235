import Router from './route/Route'
import './css/style.css'
import './css/responsive.css'
import { appConstants } from "./utils/appConstants";
import { Provider } from "react-redux";
import { store } from "./redux/index";
import { useNavigate } from 'react-router-dom';
import { getNavigate } from './apiKit/method';


const App = () => {

  document.title = appConstants.appName

  const navigate = useNavigate()

  getNavigate(navigate)

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
