import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* loginSaga({ payload, navigate }) {

    try {

        let response = yield call(api.loginApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            const token = response?.data?.data?.token
            payload.remember ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token)

            yield put({ type: apiConstants.apiLoginSuccess });
            navigate('/dashboard');
            showMessage(appConstants.loggedIn, "success-notification");
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}

function* forgotPasswordSaga({ payload, navigate }) {

    try {

        let response = yield call(api.forgotPasswordApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: apiConstants.apiForgotPasswordSuccess });
            showMessage(appConstants.forgotPasswordLinkSent, "success-notification");
            navigate('/verify-otp', { state: { email: payload.email } });
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}


function* verifyOtpSaga({ payload, navigate }) {

    try {

        let response = yield call(api.verifyOtpApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: apiConstants.apiVerifyOtpSuccess });
            showMessage(appConstants.otpVerified, "success-notification");
            navigate('/reset-password', { state: { token: response?.data?.data } });
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}

function* resetPasswordSaga({ payload, navigate }) {

    try {

        let response = yield call(api.resetPasswordApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: apiConstants.apiResetPasswordSuccess });
            showMessage(appConstants.passwordChanged, "success-notification");
            navigate('/login');
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}

function* logoutSaga({ payload, navigate }) {

    try {

        let response = yield call(api.logoutApi, payload);

        if (response?.status >= 200 && response?.status < 400) {


            localStorage.clear()
            sessionStorage.clear()
            yield put({ type: apiConstants.apiLogoutSuccess });
            showMessage(appConstants.loggedOut, "success-notification");
            navigate('/login');
        }
        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}

function* dashboardSaga({ payload, navigate }) {

    try {

        let response = yield call(api.dashboardApi, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiDashboardSuccess, result: response?.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiAuthFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiAuthFailed });
    }
}

export default function* rootAuthenticationSaga() {

    yield takeLatest(apiConstants.apiLoginInitiate, loginSaga);
    yield takeLatest(apiConstants.apiForgotPasswordInitiate, forgotPasswordSaga);
    yield takeLatest(apiConstants.apiVerifyOtpInitiate, verifyOtpSaga);
    yield takeLatest(apiConstants.apiResetPasswordInitiate, resetPasswordSaga);
    yield takeLatest(apiConstants.apiLogoutInitiate, logoutSaga);
    yield takeLatest(apiConstants.apiDashboardInitiate, dashboardSaga);

}
