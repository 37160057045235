import React, { useEffect } from "react";
import * as echarts from "echarts";

const DashboardCategoryChart = ({ data }) => {

    const colorList = [
        {
            background: "rgba(229, 54, 17, 0.90)",
            color: "#fff"
        },
        {
            background: "#F4511E",
            color: "#fff"
        },
        {
            background: "rgba(247, 115, 64, 0.90)",
            color: "#fff"
        },
        {
            background: "rgba(250, 164, 119, 0.90)",
            color: "#3A3A3C"
        },
        {
            background: "#FEE6D6",
            color: "#3A3A3C"
        }
    ]
    const chartData = data?.topCategory?.map((item, index) => ({ value: Math.round(item?.user), name: item?.name, itemStyle: { color: colorList[index]['background'] }, label: { show: true, color: colorList[index]['color'], position: "inside", fontSize: 15, fontWeight: "bold", formatter: (item) => item.data.value ? `${item.data.value}%` : "" } }))

    useEffect(() => {

        const chartDom = document.getElementById('category-chart-wrapper');
        const myChart = echarts.init(chartDom);

        const option = {

            tooltip: {
                trigger: 'item'
            },

            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '75%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        color: "#fff",
                        textBorderColor: "#fff",
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: chartData
                }
            ]
        };

        option && myChart.setOption(option);
        // myChart.resize()

        // window.addEventListener('resize', () => myChart.resize());

        return () => {
            // window.removeEventListener('resize', () => myChart.resize());
            myChart.dispose();
        };
    }, [data]);

    return <div id="category-chart-wrapper" style={{ width: "100%", height: "400px", opacity: "0.8", display: "flex", justifyContent: "center", alignItems: "center" }}></div>
};

export default DashboardCategoryChart;
