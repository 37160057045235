import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Select, Pagination } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchAction, categoryListAction, deleteCategoryAction, addCategoryAction, editCategoryAction, addServiceAction, deleteServiceAction, editServiceAction } from "../../redux/action";
import { addCategorySchema, addSubCategorySchema } from "../../utils/validation";
import { useFormik } from "formik";

const ServiceCategories = () => {

    const dispatch = useDispatch()

    const [showAddNewCategoryModal, setShowAddNewCategoryModal] = useState(false)
    const [showAddNewSubCategoryModal, setShowAddNewSubCategoryModal] = useState(false)
    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
    const [showDeleteSubCategoryModal, setShowDeleteSubCategoryModal] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [offsetData, setOffsetData] = useState({ offset: 0, limit: 10 })
    const [currentPage, setCurrentPage] = useState(1)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [categoryImage, setCategoryImage] = useState(null)
    const [serviceImage, setServiceImage] = useState(null)

    const { loading, categoryListData } = useSelector(x => x.categoryManagement)
    const { searchValue } = useSelector(x => x.authentication)

    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)

    let prevOffset = ((currentPage - 1) * offsetData.limit) - offsetData.limit
    let nextOffset = ((currentPage + 1) * offsetData.limit) - offsetData.limit

    const formikForAddCategory = useFormik({

        enableReinitialize: true,
        initialValues: {
            name: "",
            subCategory: ""
        },
        onSubmit: (values) => {

            const formData = new FormData()

            formData.append("name", values.name)
            if (categoryImage) formData.append('categoryMedia', categoryImage)
            if (values.subCategory) formData.append('subCategory', values.subCategory)
            if (serviceImage) formData.append('serviceMedia', serviceImage)

            dispatch(addCategoryAction({ payload: formData, list: { ...offsetData, search: searchValue } }))

            setShowAddNewCategoryModal(false)
            formikForAddCategory.resetForm()
            setCategoryImage(null)
        },
        validationSchema: addCategorySchema
    })

    const formikForAddSubCategory = useFormik({

        enableReinitialize: true,
        initialValues: {
            name: ""
        },
        onSubmit: (values) => {

            const formData = new FormData()

            formData.append("name", values.name)
            formData.append("categoryId", currentCategory?._id)
            categoryImage && formData.append("media", categoryImage)

            dispatch(addServiceAction({ payload: formData, list: { ...offsetData, search: searchValue } }))

            setShowAddNewSubCategoryModal(false)
            formikForAddSubCategory.resetForm()
            setCategoryImage(null)
        },
        validationSchema: addSubCategorySchema
    })

    const formikForEditCategory = useFormik({

        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        onSubmit: (values) => {

            const formData = new FormData()

            formData.append("name", values.name)
            formData.append(currentCategory?.subCategory ? "categoryId" : "serviceId", currentCategory?._id)
            categoryImage && formData.append("media", categoryImage)

            if (currentCategory?.subCategory) dispatch(editCategoryAction({ payload: formData, list: { ...offsetData, search: searchValue } }))
            else dispatch(editServiceAction({ payload:formData, list: { ...offsetData, search: searchValue } }))

            setShowEditCategoryModal(false)
            formikForEditCategory.resetForm()
            setCategoryImage(null)
        },
        validationSchema: addCategorySchema
    })

    const columns = [
        {
            title: appConstants.serviceCategories,
            dataIndex: "name",
            key: "name",
            sorter: false,
            width: "80%",
            render: (title, data, index) => (<>{data.name || "N/A"}</>)
        },
        {
            title: appConstants?.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon margin-right-150 edit-icon" src={images.edit} alt="" onClick={(e) => {
                            e.stopPropagation()
                            setCurrentCategory(data)
                            formikForEditCategory.setValues({ name: data?.name || "" })
                            formikForEditCategory.setErrors({})
                            setShowEditCategoryModal(true)

                        }} />
                        <img className="action-icon margin-right-150" src={images.trash} alt="" onClick={(e) => {
                            e.stopPropagation()
                            setCurrentCategory(data)
                            showModal()
                        }} />

                    </div>
                );
            },
        },
        {
            title: "",
            dataIndex: "",
            key: "none",
            sorter: false,
            align: "right",
            render: (title, data, index) => <img className="row-down-icon" src={images.downArrow} alt="" onClick={() => { }} />
        },
    ];

    useEffect(() => {

        dispatch(handleSearchAction({ value: "" }))
    }, [])

    useEffect(() => {

        if (searchValue) dispatch(categoryListAction({ offset: 0, limit: offsetData.limit, search: searchValue }))
        else dispatch(categoryListAction({ ...offsetData, search: searchValue }))

        setExpandedRowKeys([])

    }, [offsetData, searchValue])

    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.serviceCategories}

                    <button onClick={() => {

                        formikForAddCategory.resetForm()
                        setShowAddNewCategoryModal(true)
                    }}>+ {appConstants.add}</button>
                </div>

                <Breadcrumbs
                    path={["dashboard", "customer-management"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.manage, appConstants.serviceCategories]}
                />
            </div>

            <Table

                className="table-wrapper"
                dataSource={categoryListData?.list?.map((i, index) => ({ ...i, key: index }))}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
                onExpand={(expanded, record) => {

                    const keys = expandedRowKeys
                    const alreadyExpand = keys.indexOf(record.key)

                    alreadyExpand > -1 ? delete keys[alreadyExpand] : keys.push(record.key)
                    setCurrentCategory(record)
                }}
                expandedRowKeys={expandedRowKeys}
                expandable={{
                    expandedRowRender: (record) => (
                        <div className="expand-row">

                            <div className="row-header">

                                <div className="left">

                                    Add subcategories

                                    <button className="add-btn" onClick={() => {

                                        formikForAddSubCategory.resetForm()
                                        setCurrentCategory(record)
                                        setShowAddNewSubCategoryModal(true)
                                    }}>+ {appConstants.add}</button>

                                </div>

                                <div className="right">
                                    {appConstants.action}
                                </div>
                            </div>
                            {
                                record?.subCategory?.length ? <div className="row-body">

                                    {
                                        record?.subCategory?.map((item, index) => {

                                            return <div key={index} className="body-row">
                                                <div className="left">

                                                    {item?.name}

                                                </div>

                                                <div className="right">

                                                    <div className="table-action-button-wrapper">

                                                        <img className="action-icon margin-right-150 edit-icon" src={images.edit} alt="" onClick={() => {
                                                            setCurrentCategory(item)
                                                            formikForEditCategory.setValues({ name: item?.name || "" })
                                                            formikForEditCategory.setErrors({})
                                                            setShowEditCategoryModal(true)


                                                        }} />
                                                        <img className="action-icon margin-right-150" src={images.trash} alt="" onClick={() => {
                                                            setCurrentCategory(item)
                                                            setShowDeleteSubCategoryModal(true)
                                                        }} />

                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                                    :
                                    <div className="no-record">{appConstants.noDataFound}</div>
                            }

                        </div>

                    )
                }}

                expandRowByClick={true}
            />

            <div className="pagination-wrapper">

                <div className="select-wrapper">

                    Pages :

                    <Select

                        suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                        className="select"
                        defaultValue={10}
                        options={[
                            {
                                value: 10,
                                label: '10 Rows',
                            },
                            {
                                value: 20,
                                label: '20 Rows',
                            },
                            {
                                value: 30,
                                label: '30 Rows',
                            },
                        ]}
                        onChange={(e) => setOffsetData({ ...offsetData, limit: e })}
                    />
                </div>


                <Pagination

                    className="pagination"
                    current={currentPage}
                    total={categoryListData?.pagination?.totalCount}
                    showLessItems={false}
                    onChange={(e) => {

                        setCurrentPage(e)
                        setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                    }}
                    pageSize={offsetData.limit}
                />

                <div className="action-wrapper" >

                    <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                        if (prevOffset >= 0) {
                            setOffsetData({ ...offsetData, offset: prevOffset })
                            setCurrentPage(currentPage - 1)
                        }
                    }}>

                        <ArrowLeftOutlined className="left-icon" />
                        {appConstants.previous}
                    </span>

                    <span className={nextOffset < (categoryListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                        if (nextOffset < (categoryListData?.pagination?.totalCount || 0)) {
                            setOffsetData({ ...offsetData, offset: nextOffset })
                            setCurrentPage(currentPage + 1)
                        }
                    }}>

                        {appConstants.next}
                        <ArrowRightOutlined className="right-icon" />

                    </span>
                </div>
            </div>


            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        dispatch(deleteCategoryAction({ payload: { categoryId: currentCategory?._id }, list: { ...offsetData, search: searchValue } }))
                        hideModal()
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={hideModal}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteCategoryWarning}
            </Modal>


            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={showDeleteSubCategoryModal}
                onCancel={() => setShowDeleteSubCategoryModal(false)}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        dispatch(deleteServiceAction({ payload: { serviceId: currentCategory?._id }, list: { ...offsetData, search: searchValue } }))
                        setShowDeleteSubCategoryModal(false)
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={() => setShowDeleteSubCategoryModal(false)}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteCategoryWarning}
            </Modal>


            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showAddNewCategoryModal}
                footer={false}
                onCancel={() => setShowAddNewCategoryModal(false)}
            >
                <form className="add-provider-wrapper" onSubmit={formikForAddCategory.handleSubmit}>

                    <div className="fields-wrapper">
                        <label>{appConstants.serviceCategory}</label>

                        <div className="add-service-input-wrapper">
                            <input

                                placeholder={appConstants.serviceCategory}
                                name="name"
                                value={formikForAddCategory.values.name}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formikForAddCategory.handleChange(e)
                                }}
                                maxLength={30}
                            />

                            <input id="uploadCategoryIcon" type="file" accept="image/*" style={{ display: "none" }} onChange={e => e.target.files[0] ? setCategoryImage(e.target.files[0]) : null} />

                            <label className="serice-image-upload-input" htmlFor="uploadCategoryIcon">
                                <img src={images.backupIcon} alt="" />
                                <span>{appConstants.uploadIcon}</span>
                                <p>Supports: PNG | Size : 50 x 50px</p>

                            </label>

                        </div>
                        {formikForAddCategory.errors.name && <div className="validation">{formikForAddCategory.errors.name}</div>}

                    </div>

                    <div className="fields-wrapper">
                        <label>{appConstants.subcategory}</label>
                        <div className="add-service-input-wrapper">
                            <input
                                placeholder={appConstants.subcategory}
                                name="subCategory"
                                value={formikForAddCategory.values.subCategory}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formikForAddCategory.handleChange(e)
                                }}
                                maxLength={30}
                            />

                            <input id="uploadServiceIcon" type="file" accept="image/*" style={{ display: "none" }} onChange={e => e.target.files[0] ? setServiceImage(e.target.files[0]) : null} />

                            <label className="serice-image-upload-input" htmlFor="uploadServiceIcon">
                                <img src={images.backupIcon} alt="" />
                                <span>{appConstants.uploadIcon}</span>
                                <p>Supports: PNG | Size : 50 x 50px</p>

                            </label>

                        </div>


                        {formikForAddCategory.errors.subCategory && <div className="validation">{formikForAddCategory.errors.subCategory}</div>}

                    </div>


                    <div className="btn-wrapper">

                        <button className="cancel" type="button" onClick={() => {
                            setShowAddNewCategoryModal(false)
                            formikForAddCategory.resetForm()
                        }}>{appConstants.cancel}</button>
                        <button className="submit" type="submit" >{appConstants.submit}</button>

                    </div>
                </form>

            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showAddNewSubCategoryModal}
                footer={false}
                onCancel={() => setShowAddNewSubCategoryModal(false)}
            >
                <form className="add-provider-wrapper" onSubmit={formikForAddSubCategory.handleSubmit}>

                    <div className="fields-wrapper">
                        <label>{appConstants.serviceCategory}</label>
                        <input

                            placeholder={appConstants.serviceCategory}
                            name="name"
                            value={currentCategory?.name || "N/A"}
                            maxLength={30}
                            disabled={true}
                        />

                    </div>

                    <div className="fields-wrapper">
                        <label>{appConstants.subcategory}</label>
                        <div className="add-service-input-wrapper">

                            <input
                                placeholder={appConstants.subcategory}
                                name="name"
                                value={formikForAddSubCategory.values.name}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formikForAddSubCategory.handleChange(e)
                                }}
                                maxLength={30}
                            />

                            <input id="uploadCategoryIcon" type="file" accept="image/*" style={{ display: "none" }} onChange={e => e.target.files[0] ? setCategoryImage(e.target.files[0]) : null} />

                            <label className="serice-image-upload-input" htmlFor="uploadCategoryIcon">
                                <img src={images.backupIcon} alt="" />
                                <span>{appConstants.uploadIcon}</span>
                                <p>Supports: PNG | Size : 50 x 50px</p>

                            </label>
                        </div>

                        {formikForAddSubCategory.errors.name && <div className="validation">{formikForAddSubCategory.errors.name}</div>}

                    </div>


                    <div className="btn-wrapper">

                        <button className="cancel" type="button" onClick={() => setShowAddNewSubCategoryModal(false)}>{appConstants.cancel}</button>
                        <button className="submit" type="submit" >{appConstants.submit}</button>

                    </div>
                </form>

            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showEditCategoryModal}
                footer={false}
                onCancel={() => {

                    setShowEditCategoryModal(false)
                    formikForEditCategory.resetForm()
                }}
            >
                <form className="add-provider-wrapper" onSubmit={formikForEditCategory.handleSubmit}>

                    <div className="fields-wrapper">
                        <label>{currentCategory?.subCategory ? appConstants.category : appConstants.subCategory}</label>
                        <div className="add-service-input-wrapper">
                            <input
                                placeholder={appConstants.category}
                                name="name"
                                value={formikForEditCategory.values.name}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formikForEditCategory.handleChange(e)
                                }}
                                maxLength={30}
                            />

                            <input id="uploadCategoryIcon" type="file" accept="image/*" style={{ display: "none" }} onChange={e => e.target.files[0] ? setCategoryImage(e.target.files[0]) : null} />

                            <label className="serice-image-upload-input" htmlFor="uploadCategoryIcon">
                                <img src={images.backupIcon} alt="" />
                                <span>{appConstants.uploadIcon}</span>
                                <p>Supports: PNG | Size : 50 x 50px</p>

                            </label>
                        </div>
                        {formikForEditCategory.errors.name && <div className="validation">{formikForEditCategory.errors.name}</div>}

                    </div>


                    <div className="btn-wrapper">

                        <button type="button" className="cancel" onClick={() => {

                            setShowEditCategoryModal(false)
                            formikForEditCategory.resetForm()
                        }}>{appConstants.cancel}</button>
                        <button className="submit" type="submit" >{appConstants.submit}</button>

                    </div>
                </form>

            </Modal>

        </div>
    )
}

export default ServiceCategories;