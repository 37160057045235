import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    resetPasswordLoading: true,
    resetPasswordLink: false,
    searchValue: "",
    searchOnchange: () => null,
    dashboard: {}
};

export const authenticationReducer = (state = initialState, action) => {

    switch (action.type) {

        // Login
        case apiConstants.apiLoginInitiate: return { ...state, loading: true };
        case apiConstants.apiLoginSuccess: return { ...state, loading: false };

        // Logout
        case apiConstants.apiLogoutInitiate: return { ...state, loading: true };
        case apiConstants.apiLogoutSuccess: return { ...state, loading: false };

        // Verify OTP
        case apiConstants.apiVerifyOtpInitiate: return { ...state, loading: true };
        case apiConstants.apiVerifyOtpSuccess: return { ...state, loading: false };

        // Forgot Password
        case apiConstants.apiForgotPasswordInitiate: return { ...state, loading: true };
        case apiConstants.apiForgotPasswordSuccess: return { ...state, loading: false };

        // Forgot Password link
        case apiConstants.apiResetPasswordLinkInitiate: return { ...state, loading: true, resetPasswordLoading: true };
        case apiConstants.apiResetPasswordLinkSuccess: return { ...state, loading: false, resetPasswordLoading: false, resetPasswordLink: true };
        case apiConstants.apiResetPasswordLinkFailed: return { ...state, loading: false, resetPasswordLoading: false, resetPasswordLink: false };

        // Dashboard
        case apiConstants.apiDashboardInitiate: return { ...state, loading: true };
        case apiConstants.apiDashboardSuccess: return { ...state, loading: false, dashboard: action?.result?.data };

        // auth failed
        case apiConstants.apiAuthFailed: return { ...state, loading: false };


        // Handle Search
        case apiConstants.handleSearchOnChange: return { ...state, searchValue: action?.payload?.value, searchOnchange: action?.payload?.onChange };

        //Default case
        default: return { ...state };
    }
};
