import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import DashboardCategoryChart from "../common/dashboardCategoryChart";
import DashboardActiveAccountChart from "../common/dashboardActiveAcountChart";
import { useNavigate } from "react-router-dom";
import DashboardCustomerChart from "../common/dashboardCustomerChart";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from "../../redux/action";

const Dashboard = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const dashboard = useSelector(x => x.authentication.dashboard)

    const colorList = [
        {
            background: "rgba(229, 54, 17, 0.90)",
            color: "#fff"
        },
        {
            background: "#F4511E",
            color: "#fff"
        },
        {
            background: "rgba(247, 115, 64, 0.90)",
            color: "#fff"
        },
        {
            background: "rgba(250, 164, 119, 0.90)",
            color: "#3A3A3C"
        },
        {
            background: "#FEE6D6",
            color: "#3A3A3C"
        }
    ]

    useEffect(() => {

        dispatch(dashboardAction())

    }, [])

    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.dashboard}


                </div>

                <Breadcrumbs
                    path={[]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.dashboard]}
                />
            </div>

            <div className="dashboard-row">

                <div className="col">
                    <div className="dashboard-data-wrapper-row">

                        <div className="col float-top-bottom">
                            <div className="heading">{appConstants.totalCustomers}</div>

                            <div className="footer">

                                <div className="value">{dashboard?.totalCustomer || ""}</div>

                                <div className="percentage">{Math.floor(dashboard?.todayAddCustomer) || "0"}%</div>
                            </div>

                        </div>

                        <div className="col float-top-bottom">
                            <div className="heading">{appConstants.totalServiceProvider}</div>
                            <div className="footer">

                                <div className="value">{dashboard?.totalServiceProvider || ""}</div>

                                <div className="percentage">{Math.floor(dashboard?.todayAddProvider) || "0"}%</div>
                            </div>

                        </div>

                        <div className="col">
                            <div className="heading">{appConstants.stats}</div>

                            <div className="type-wrapper">

                                <div className="content">

                                    <div className="dot" style={{ background: "rgba(244, 81, 30, 0.90)" }}></div>
                                    <div>
                                        {appConstants.customers}
                                    </div>

                                </div>


                                <div className="content">

                                    <div className="dot" style={{ background: "rgba(250, 164, 119, 0.90)" }}></div>
                                    <div>
                                        {appConstants.serviceProvider}
                                    </div>

                                </div>

                            </div>
                            <div className="chart-wrapper">

                                <DashboardCustomerChart data={dashboard} />
                                <div className="value">{Math.round((dashboard?.totalCustomer / (dashboard?.totalCustomer + dashboard?.totalServiceProvider)) * 100 || 0)}%</div>

                            </div>

                        </div>

                        <div className="col">
                            <div className="heading">{appConstants.activeAccounts}</div>

                            <div className="type-wrapper">

                                <div className="content">

                                    <div className="dot" style={{ background: "rgba(244, 81, 30, 0.90)" }}></div>
                                    <div>
                                        {appConstants.active}
                                    </div>

                                </div>


                                <div className="content">

                                    <div className="dot" style={{ background: "rgba(250, 164, 119, 0.90)" }}></div>
                                    <div>
                                        {appConstants.signout}
                                    </div>

                                </div>

                            </div>
                            <div className="chart-wrapper">

                                <DashboardActiveAccountChart data={dashboard} />

                                <div className="description">{appConstants.totalActiveAccounts}</div>
                                <div className="value">{dashboard?.activeAccount || 0}</div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col">

                    <div className="category-graph-wrapper">
                        <div className="heading">{appConstants.top5Categories}</div>

                        <div className="category-flex-wrapper">
                            <div className="chart-wrapper">

                                {/* <img src={images.chart3} alt="" /> */}
                                <DashboardCategoryChart data={dashboard} />

                            </div>

                            <div className="category-data-wrapper">


                                {
                                    dashboard?.topCategory?.map((item, index) => {
                                        return <div key={index} className="category-name-wrapper">
                                            <span className="name-wrapper">
                                                <div className="dot" style={{ background: colorList[index].background }}></div>
                                                <div className="name">{item.name}</div>
                                            </span>

                                            <div className="value" style={{ background: colorList[index].background, color: colorList[index].color }}>{`${Math.round(item.user)}%`}</div>

                                        </div>
                                    })
                                }

                                <button onClick={() => navigate("/service-categories")}>{appConstants.viewAllCategory}</button>

                            </div>
                        </div>

                    </div>
                </div>

            </div>


        </div>
    )
}

export default Dashboard;