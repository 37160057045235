import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    categoryListData: {},
    categoryListForDropdown: [],
    subCategoryListForDropdown: []
};

export const categoryManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // Category list
        case apiConstants.apiCatgoryListInitiate: return { ...state, loading: true };
        case apiConstants.apiCatgoryListSuccess: return { ...state, loading: false, categoryListData: action?.result?.data, categoryListForDropdown: action?.result?.data?.list?.map(i => ({ label: i.name, value: i._id, selected: i.isSelected, subCategory: i.subCategory.map(item => ({ label: item.name, value: item._id, selected: item.isSelected })) })) };

        // Delete category
        case apiConstants.apiDeleteCatgoryInitiate: return { ...state, loading: true };
        case apiConstants.apiDeleteCatgorySuccess: return { ...state, loading: false };

        // Category add
        case apiConstants.apiAddCatgoryInitiate: return { ...state, loading: true };
        case apiConstants.apiAddCatgorySuccess: return { ...state, loading: false };

        // Failed
        case apiConstants.apiCategoryManagementFailed: return { ...state, loading: false };

        //Default case
        default: return { ...state };
    }
};
