import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const Breadcrumbs = ({ path, pathName }) => {

    const totalBreadcrumbItem = () => {

        return pathName?.map((item, index) => {

            return path[index] ? (
                <Breadcrumb.Item key={index}>
                    <Link to={`/${path[index]}`}>{item}</Link>
                </Breadcrumb.Item>
            ) : (
                    <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                );
        });
    };
    return <Breadcrumb separator={<RightOutlined className="saperator" />} className="breadcrumb-wrapper">{totalBreadcrumbItem()}</Breadcrumb>;
};

export default Breadcrumbs;