import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    reviewListData: {},
};

export const reviewManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // Review list
        case apiConstants.apiReviewListInitiate: return { ...state, loading: true };
        case apiConstants.apiReviewListSuccess: return { ...state, loading: false, reviewListData: action?.result?.data };

        // Delete Review
        case apiConstants.apiReviewDeleteInitiate: return { ...state, loading: true };
        case apiConstants.apiReviewDeleteSuccess: return { ...state, loading: false };

        // Block Review
        case apiConstants.apiReviewBlockInitiate: return { ...state, loading: true };
        case apiConstants.apiReviewBlockSuccess: return {
            ...state,
            loading: false,
            reviewListData: {
                ...state.reviewListData,
                list: JSON.parse(JSON.stringify(state?.reviewListData?.list)).map(item => {
                    if (action?.payload?.ratingId === item?._id) item.isBlock = item.isBlock ? false : true

                    return item
                })
            }
        };

        // Failed
        case apiConstants.apiReviewManagementFailed: return { ...state, loading: false };

        //Default case
        default: return { ...state };
    }
};
