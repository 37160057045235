import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import { appConstants } from '../../utils/appConstants';
import React, { useState, useRef, useEffect } from 'react';
import { verifyOtpAction, forgotPasswordAction } from '../../redux/action/index'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd';

const VerifyOtp = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const email = location?.state?.email
    const loading = useSelector(x => x.authentication.loading)

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timer, setTimer] = useState(59);

    const inputRefs = useRef(otp.map(i => React.createRef()));

    const handleOtpChange = (index, value) => {
        if (/^\d$/.test(value) || value === '') {
            setOtp((prevOtp) => {
                const newOtp = [...prevOtp];
                newOtp[index] = value;

                if (/^\d$/.test(value) && index < inputRefs.current.length - 1) {
                    inputRefs.current[index + 1].current.focus();
                }

                return newOtp;
            });
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !otp[index]) {
            e.preventDefault();
            inputRefs.current[index - 1].current.focus();
        }
    };

    useEffect(() => {

        const countdown = () => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        };

        const intervalId = setInterval(countdown, 1000);
        return () => clearInterval(intervalId);
    }, [timer]);

    const handleSubmit = (e) => {

        e.preventDefault()
        const otpValue = otp.join().replace(/,/g, "")
        otpValue.length === 6 && dispatch(verifyOtpAction({ email, otp: otpValue }, navigate))

    }

    useEffect(() => {

        if (!email) navigate('/login')

    }, [])

    return (
        <div className="auth-wrapper forgot-password-wrapper">

            <form onSubmit={handleSubmit}>

                <div className="form-content">

                    <div className="heading">
                        {appConstants.otpVerification}
                    </div>

                    <span className="sub-heading">
                        {appConstants.otpVerificationText}
                    </span>

                    <div className="otp-input-wrapper">

                        {
                            otp.map((item, index) => {

                                return <input

                                    key={index}
                                    value={item}
                                    className="otp-input"
                                    maxLength={1}
                                    type="text"
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    ref={inputRefs.current[index]}
                                />
                            })
                        }
                    </div>


                    <div className="button-wrapper">
                        <button disabled={loading} type="submit">{loading ? <Spin /> : appConstants.verify}</button>
                    </div>


                    <div className="resend-otp-wrapper">
                        {!timer ? <div className="resend-otp-link" onClick={() => {

                            setTimer(59)
                            dispatch(forgotPasswordAction({ email }, navigate))
                        }}>Resend Code</div> : appConstants.resendOtpText + (timer < 10 ? "0" + timer : timer)}
                    </div>
                </div>
            </form>

            <div className="footer">© {moment().format("YYYY")},{appConstants.authCopyRightText}</div>

        </div>
    )
}

export default VerifyOtp;