import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* reportListSaga({ payload }) {

    try {

        let response = yield call(api.reportList, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiReportListSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiReportManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiReportManagementFailed });
    }
}

export default function* rootReportManagementSaga() {

    yield takeLatest(apiConstants.apiReportListInitiate, reportListSaga);
}
