import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom'
import { appConstants } from '../../utils/appConstants'
import { useState, useEffect } from 'react'
import images from '../../utils/images'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordAction } from '../../redux/action/index'
import { useFormik } from 'formik'
import { resetPasswordSchema } from '../../utils/validation'
import { Spin } from 'antd'

const ResetPassword = () => {

    const location = useLocation()
    const token = location?.state?.token
    const loading = useSelector(x => x.authentication.loading)

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false })

    const formik = useFormik({

        initialValues: {
            confirmPassword: "",
            password: ""
        },
        onSubmit: (values) => dispatch(resetPasswordAction({ password: values.password, token }, navigate)),
        validationSchema: resetPasswordSchema
    })

    useEffect(() => {

        if (!token) navigate('/login')

    }, [])

    return (
        <div className="auth-wrapper forgot-password-wrapper">

            <form onSubmit={formik.handleSubmit}>

                <div className="form-content">

                    <div className="heading">
                        {appConstants.createNewPassword}
                    </div>

                    <span className="sub-heading">
                        {appConstants.createPasswordText}
                    </span>

                    <div className="input-wrapper">

                        <input

                            name="password"
                            onChange={(e) => {

                                e.target.value = e.target.value.replace(/ /g, "")
                                formik.handleChange(e)
                            }}
                            value={formik.values.password}

                            className="reset-password-input"
                            type={showPassword.password ? "text" : "password"}
                            placeholder={appConstants.createNewPassword}
                        />
                        {showPassword.password ? <img src={images.visible} alt="" onClick={() => setShowPassword({ ...showPassword, password: false })} /> : <img src={images.invisible} alt="" onClick={() => setShowPassword({ ...showPassword, password: true })} />}

                    </div>
                    {formik.errors.password && <div className="validation">{formik.errors.password}</div>}


                    <div className="input-wrapper">

                        <input

                            name="confirmPassword"
                            onChange={(e) => {

                                e.target.value = e.target.value.replace(/ /g, "")
                                formik.handleChange(e)
                            }}
                            value={formik.values.confirmPassword}

                            className="reset-password-input" type={showPassword.confirmPassword ? "text" : "password"} placeholder={appConstants.confirmNewPassword} />
                        {showPassword.confirmPassword ? <img src={images.visible} alt="" onClick={() => setShowPassword({ ...showPassword, confirmPassword: false })} /> : <img src={images.invisible} alt="" onClick={() => setShowPassword({ ...showPassword, confirmPassword: true })} />}

                    </div>
                    {formik.errors.confirmPassword && <div className="validation">{formik.errors.confirmPassword}</div>}


                    <div className="button-wrapper">
                        <button disabled={loading} type="submit">{loading ? <Spin /> : appConstants.reset}</button>
                    </div>

                </div>
            </form>

            <div className="footer">© {moment().format("YYYY")},{appConstants.authCopyRightText}</div>

        </div>
    )
}

export default ResetPassword;