import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    serviceListData: {},
};

export const serviceManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // Service list
        case apiConstants.apiServiceListInitiate: return { ...state, loading: true };
        case apiConstants.apiServiceListSuccess: return { ...state, loading: false, serviceListData: action?.result?.data };

        // Change service status
        case apiConstants.apiChangeTrendingServiceStatusInitiate: return { ...state, loading: true };
        case apiConstants.apiChangeTrendingServiceStatusSuccess: return {
            ...state, loading: false,
            serviceListData: {
                ...state?.serviceListData,
                list: JSON.parse(JSON.stringify(state?.serviceListData.list)).map(i => {
                    if (i._id === action?.payload?.serviceId) i.trending = !i.trending

                    return i
                })
            }
        };

        // Add Service
        case apiConstants.apiAddServiceInitiate: return { ...state, loading: true };
        case apiConstants.apiAddServiceSuccess: return { ...state, loading: false };

        // Delete Service
        case apiConstants.apiDeleteServiceInitiate: return { ...state, loading: true };
        case apiConstants.apiDeleteServiceSuccess: return { ...state, loading: false };

        // Edit Service
        case apiConstants.apiEditServiceInitiate: return { ...state, loading: true };
        case apiConstants.apiEditServiceSuccess: return { ...state, loading: false };

        // Failed
        case apiConstants.apiServiceManagementFailed: return { ...state, loading: false };

        //Default case
        default: return { ...state };
    }
};
