import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* reviewListSaga({ payload }) {

    try {

        let response = yield call(api.reviewList, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiReviewListSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiReviewManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiReviewManagementFailed });
    }
}

function* reviewDeleteSaga({ payload }) {

    try {

        let response = yield call(api.deleteReview, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.ratingDeleted, "success-notification");
            yield put({ type: apiConstants.apiReviewDeleteSuccess });
            yield put({ type: apiConstants.apiReviewListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiReviewManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiReviewManagementFailed });
    }
}

function* reviewBlockSaga({ payload }) {

    try {

        let response = yield call(api.blockReview, payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(response.message, "success-notification");
            yield put({ type: apiConstants.apiReviewBlockSuccess });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiReviewManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiReviewManagementFailed });
    }
}

export default function* rootReviewManagementSaga() {

    yield takeLatest(apiConstants.apiReviewListInitiate, reviewListSaga);
    yield takeLatest(apiConstants.apiReviewDeleteInitiate, reviewDeleteSaga);
    yield takeLatest(apiConstants.apiReviewBlockInitiate, reviewBlockSaga);
}
