import Layout, { Content } from "antd/es/layout/layout";
import Sidebar from "./Sidebar";
import MainHeader from "./Header";
import { useState } from "react";
import { Outlet } from 'react-router-dom'

const MainLayout = (props) => {

    const [collapse, setCollapse] = useState(window.innerWidth < 900 ? true : false)
    const handleClick = () => setCollapse(!collapse)

    window.addEventListener('resize', () => window.innerWidth < 900 ? setCollapse(true) : setCollapse(false));

    return (
        <Layout style={{ height: "100vh" }}>
            <Sidebar collapse={collapse} />
            <Layout>
                <Content id="content-wrapper" className="content-wrapper">
                    <MainHeader search={props.search} collapse={collapse} handleClick={handleClick} />
                    <div className="outlet-wrapper">
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default MainLayout;