import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Switch, Pagination, Select, Modal, Rate } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { handleSearchAction, reviewBlockAction, reviewDeleteAction, reviewListAction } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

const ReviewManagement = () => {

    const dispatch = useDispatch()

    const [showViewModal, setViewModal] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)
    const [offsetData, setOffsetData] = useState({ offset: 0, limit: 10 })
    const [currentPage, setCurrentPage] = useState(1)
    const [currentReview, setCurrentReview] = useState(null)

    const { searchValue } = useSelector(x => x.authentication)
    const { loading, reviewListData } = useSelector(x => x.reviewManagement)

    useEffect(() => {

        dispatch(handleSearchAction({ value: "" }))
    }, [])

    useEffect(() => {

        if (searchValue) dispatch(reviewListAction({ offset: 0, limit: offsetData.limit, search: searchValue }))

        else dispatch(reviewListAction({ ...offsetData, search: searchValue }))
    }, [offsetData, searchValue])


    let prevOffset = ((currentPage - 1) * offsetData.limit) - offsetData.limit
    let nextOffset = ((currentPage + 1) * offsetData.limit) - offsetData.limit

    const columns = [
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "120px",
            align: "center",
            render: (value, item, index) => <div><Switch defaultChecked={item?.isBlock} onChange={() => dispatch(reviewBlockAction({ ratingId: item?._id },))} /></div>,
        },
        {
            title: appConstants.sNo,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "120px",
            align: "center",
            render: (value, item, index) => index + 1,
        },

        {
            title: appConstants?.customer,
            dataIndex: "name",
            key: "name",
            sorter: false,
            render: (title, data, index) => (<>{data?.customer?.name || "N/A"}</>)
        },
        {
            title: appConstants?.provider,
            dataIndex: "provider",
            key: "provider",
            sorter: false,
            render: (title, data, index) => (<>{data?.provider?.name || "N/A"}</>)
        },
        {
            title: appConstants?.service,
            dataIndex: "service",
            key: "service",
            sorter: false,
            render: (title, data, index) => (<>{data?.service?.name || "N/A"}</>)
        },
        {
            title: appConstants?.rating,
            dataIndex: "status",
            key: "status",
            sorter: false,
            render: (title, data, index) => <div><Rate disabled={true} className="rate" allowHalf defaultValue={data?.rating || 0} /></div>
        },
        {
            title: appConstants?.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.show} alt="" onClick={() => {

                            setCurrentReview(data)
                            setViewModal(true)
                        }} />
                        <img className="action-icon" src={images.trash} alt="" onClick={() => {
                            setCurrentReview(data)
                            showModal()
                        }} />

                    </div>
                );
            },
        },
    ];
    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.reviews}

                </div>

                <Breadcrumbs
                    path={["dashboard"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.reviews]}
                />
            </div>

            <Table

                className="table-wrapper"
                dataSource={reviewListData?.list?.map((i, index) => ({ ...i, key: index }))}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
            />

            <div className="pagination-wrapper">

                <div className="select-wrapper">

                    Pages :

                    <Select

                        suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                        className="select"
                        defaultValue={10}
                        options={[
                            {
                                value: 10,
                                label: '10 Rows',
                            },
                            {
                                value: 20,
                                label: '20 Rows',
                            },
                            {
                                value: 30,
                                label: '30 Rows',
                            },
                        ]}
                        onChange={(e) => setOffsetData({ ...offsetData, limit: e })}
                    />
                </div>


                <Pagination

                    className="pagination"
                    current={currentPage}
                    total={reviewListData?.pagination?.totalCount}
                    showLessItems={false}
                    onChange={(e) => {

                        setCurrentPage(e)
                        setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                    }}

                    pageSize={offsetData.limit}
                />

                <div className="action-wrapper" >

                    <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                        if (prevOffset >= 0) {
                            setOffsetData({ ...offsetData, offset: prevOffset })
                            setCurrentPage(currentPage - 1)
                        }
                    }}>

                        <ArrowLeftOutlined className="left-icon" />
                        {appConstants.previous}
                    </span>

                    <span className={nextOffset < (reviewListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                        if (nextOffset < (reviewListData?.pagination?.totalCount || 0)) {
                            setOffsetData({ ...offsetData, offset: nextOffset })
                            setCurrentPage(currentPage + 1)
                        }
                    }}>

                        {appConstants.next}
                        <ArrowRightOutlined className="right-icon" />

                    </span>
                </div>
            </div>

            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        dispatch(reviewDeleteAction({ payload: { ratingId: currentReview?._id }, list: { ...offsetData, search: searchValue } }))
                        hideModal()
                        setViewModal(false)
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={hideModal}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteReviewWarning}
            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showViewModal}
                footer={false}
                onCancel={() => setViewModal(false)}
            >
                <div className="header">

                    <div className="heading">
                        {appConstants.reviewDetails}
                    </div>

                </div>

                <div className="personal-detail-wrapper">

                    <div className="detail-wrapper" style={{ width: "100%" }}>

                        <div className="field-wrapper">

                            <div className="field">{appConstants.customer}</div>
                            <div className="value">:  <span>&nbsp;</span>{currentReview?.customer?.name || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.provider}</div>
                            <div className="value">:  <span>&nbsp;</span>{currentReview?.provider?.name || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.category}</div>
                            <div className="value">:  <span>&nbsp;</span>{currentReview?.service?.category || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.service}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentReview?.service?.name || "N/A"}</div>
                        </div>

                        <div className="field-wrapper">

                            <div className="field">{appConstants.reviewRating}</div>
                            <div className="value">:  <span>&nbsp;</span>{currentReview?.rating || 0} <Rate disabled={true} className="rate" allowHalf defaultValue={currentReview?.rating || 0} /></div>
                        </div>

                        <div className="field-wrapper">

                            <div className="field">{appConstants.reviewComments}</div>
                            <div className="value" >: <span></span><div className="rating-comment">{currentReview?.text || "N/A"}</div></div>
                        </div>

                    </div>

                </div>

            </Modal>
        </div>
    )
}

export default ReviewManagement;