import { combineReducers } from 'redux';
import { authenticationReducer as authentication } from './authentication'
import { customerManagementReducer as customerManagement } from './customerManagement'
import { categoryManagementReducer as categoryManagement } from './categoryManagement'
import { serviceManagementReducer as serviceManagement } from './serviceManagement'
import { reportManagementReducer as reportManagement } from './reportManagemen'
import { reviewManagementReducer as reviewManagement } from './reviewManagement'

const appReducer = combineReducers({

    authentication,
    customerManagement,
    categoryManagement,
    serviceManagement,
    reportManagement,
    reviewManagement
})

const rootReducer = (state, action) => appReducer(state, action)

export default rootReducer;