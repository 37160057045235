import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../apiKit/api";
import { apiConstants, appConstants } from '../../utils/appConstants'
import { showMessage } from '../../component/common/Message'

function* serviceListSaga({ payload }) {

    try {

        let response = yield call(api.serviceList, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: apiConstants.apiServiceListSuccess, result: response.data });

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiServiceManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiServiceManagementFailed });
    }
}

function* changeServiceStatusSaga({ payload }) {

    try {

        let response = yield call(api.changeTrendingServiceStatus, payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(payload.trending ? appConstants.removeAsTrending : appConstants.setAsTrending,"success-notification");
            yield put({ type: apiConstants.apiChangeTrendingServiceStatusSuccess, payload });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiServiceManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiServiceManagementFailed });
    }
}

function* addServiceSaga({ payload }) {

    try {

        let response = yield call(api.addService, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.serviceAddedMessage,"success-notification");
            yield put({ type: apiConstants.apiAddServiceSuccess });
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiServiceManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiServiceManagementFailed });
    }
}

function* deleteServiceSaga({ payload }) {

    try {

        let response = yield call(api.deleteService, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.serviceDeleteMessage,"success-notification");
            yield put({ type: apiConstants.apiDeleteServiceSuccess });
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiServiceManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiServiceManagementFailed });
    }
}


function* editServiceSaga({ payload }) {

    try {

        let response = yield call(api.editService, payload?.payload);

        if (response?.status >= 200 && response?.status < 400) {

            showMessage(appConstants.serviceEditMessage,"success-notification");
            yield put({ type: apiConstants.apiEditServiceSuccess });
            yield put({ type: apiConstants.apiCatgoryListInitiate, payload: payload?.list });
        }

        else {

            showMessage(response.message);
            yield put({ type: apiConstants.apiServiceManagementFailed });
        }
    }
    catch (error) {

        showMessage(appConstants.messageStatus500);
        yield put({ type: apiConstants.apiServiceManagementFailed });
    }
}

export default function* rootCustomerManagementSaga() {

    yield takeLatest(apiConstants.apiServiceListInitiate, serviceListSaga);
    yield takeLatest(apiConstants.apiChangeTrendingServiceStatusInitiate, changeServiceStatusSaga);
    yield takeLatest(apiConstants.apiAddServiceInitiate, addServiceSaga);
    yield takeLatest(apiConstants.apiDeleteServiceInitiate, deleteServiceSaga);
    yield takeLatest(apiConstants.apiEditServiceInitiate, editServiceSaga);
}
