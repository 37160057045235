
import { apiConstants } from "../../utils/appConstants";

// login 
export const loginAction = (payload, navigate) => ({ type: apiConstants.apiLoginInitiate, payload, navigate });

export const forgotPasswordAction = (payload, navigate) => ({ type: apiConstants.apiForgotPasswordInitiate, payload, navigate });

export const verifyOtpAction = (payload, navigate) => ({ type: apiConstants.apiVerifyOtpInitiate, payload, navigate });

export const resetPasswordAction = (payload, navigate) => ({ type: apiConstants.apiResetPasswordInitiate, payload, navigate });

export const logoutAction = (navigate) => ({ type: apiConstants.apiLogoutInitiate, navigate });

export const customerListAction = (payload) => ({ type: apiConstants.apiCustomerListInitiate, payload });

export const customerListForCsvAction = (payload) => ({ type: apiConstants.apiCustomerListForCsvInitiate, payload });

export const customerListForCsvRemoveAction = () => ({ type: apiConstants.apiCustomerListForCsvRemove });

export const customerBlockAction = (payload) => ({ type: apiConstants.apiCustomerBlockInitiate, payload });

export const customerDeleteAction = (payload) => ({ type: apiConstants.apiCustomerDeleteInitiate, payload });

export const handleSearchAction = (payload) => ({ type: apiConstants.handleSearchOnChange, payload });

export const categoryListAction = (payload) => ({ type: apiConstants.apiCatgoryListInitiate, payload });

export const subCategoryListAction = (payload) => ({ type: apiConstants.apiSubCatgoryListInitiate, payload });

export const addProviderAction = (payload) => ({ type: apiConstants.apiAddProviderInitiate, payload });

export const editProviderAction = (payload) => ({ type: apiConstants.apiEditProviderInitiate, payload });

export const deleteCategoryAction = (payload) => ({ type: apiConstants.apiDeleteCatgoryInitiate, payload });

export const deleteServiceAction = (payload) => ({ type: apiConstants.apiDeleteServiceInitiate, payload });

export const addCategoryAction = (payload) => ({ type: apiConstants.apiAddCatgoryInitiate, payload });

export const editCategoryAction = (payload) => ({ type: apiConstants.apiEditCatgoryInitiate, payload });

export const serviceListAction = (payload) => ({ type: apiConstants.apiServiceListInitiate, payload });

export const changeTrendingServiceStatus = (payload) => ({ type: apiConstants.apiChangeTrendingServiceStatusInitiate, payload });

export const addServiceAction = (payload) => ({ type: apiConstants.apiAddServiceInitiate, payload });

export const editServiceAction = (payload) => ({ type: apiConstants.apiEditServiceInitiate, payload });

export const verifyProviderAction = (payload) => ({ type: apiConstants.apiVerifyProviderInitiate, payload });

export const dashboardAction = () => ({ type: apiConstants.apiDashboardInitiate });

export const reportListAction = (payload) => ({ type: apiConstants.apiReportListInitiate, payload });

export const importCsvFromProviderAction = (payload) => ({ type: apiConstants.apiImportProviderInitiate, payload });

export const reviewListAction = (payload) => ({ type: apiConstants.apiReviewListInitiate, payload });

export const reviewDeleteAction = (payload) => ({ type: apiConstants.apiReviewDeleteInitiate, payload });

export const reviewBlockAction = (payload) => ({ type: apiConstants.apiReviewBlockInitiate, payload });

