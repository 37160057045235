import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Pagination, Select, Checkbox } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { handleSearchAction, serviceListAction, changeTrendingServiceStatus } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

const TrendingServices = () => {

    const dispatch = useDispatch()
    const [offsetData, setOffsetData] = useState({ offset: 0, limit: 10 })
    const [currentPage, setCurrentPage] = useState(1)

    const { loading, serviceListData } = useSelector(x => x.serviceManagement)
    const { searchValue } = useSelector(x => x.authentication)

    let prevOffset = ((currentPage - 1) * offsetData.limit) - offsetData.limit
    let nextOffset = ((currentPage + 1) * offsetData.limit) - offsetData.limit

    const columns = [
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "300px",
            align: "center",
            render: (value, item, index) => <div><Checkbox className="check-box" checked={item?.trending} onChange={() => dispatch(changeTrendingServiceStatus({ serviceId: item?._id, trending: item?.trending }))} /></div>,
        },
        {
            title: appConstants?.serviceName,
            dataIndex: "name",
            key: "name",
            sorter: false,
            align: "center",
            render: (title, data, index) => (<>{data.name || "N/A"}</>)
        },
        {
            title: appConstants?.serviceCategories,
            dataIndex: "category",
            key: "category",
            sorter: false,
            align: "center",
            render: (title, data, index) => (<>{data?.category?.name || "N/A"}</>)
        },
    ];

    useEffect(() => {

        dispatch(handleSearchAction({ value: "" }))
    }, [])

    useEffect(() => {

        if (searchValue) dispatch(serviceListAction({ offset: 0, limit: offsetData.limit, search: searchValue }))

        else dispatch(serviceListAction({ ...offsetData, search: searchValue }))
    }, [offsetData, searchValue])

    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.trendingServices}
                </div>

                <Breadcrumbs
                    path={["dashboard", "customer-management"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.manage, appConstants.trendingServices]}
                />
            </div>

            <Table

                className="table-wrapper"
                dataSource={serviceListData?.list?.map((i, index) => ({ ...i, key: index }))}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
            />

            <div className="pagination-wrapper">

                <div className="select-wrapper">

                    Pages :

                    <Select

                        suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                        className="select"
                        defaultValue={10}
                        options={[
                            {
                                value: 10,
                                label: '10 Rows',
                            },
                            {
                                value: 20,
                                label: '20 Rows',
                            },
                            {
                                value: 30,
                                label: '30 Rows',
                            },
                        ]}
                        onChange={(e) => setOffsetData({ ...offsetData, limit: e })}

                    />
                </div>


                <Pagination

                    className="pagination"
                    current={currentPage}
                    total={serviceListData?.pagination?.totalCount}
                    showLessItems={false}
                    onChange={(e) => {

                        setCurrentPage(e)
                        setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                    }}
                    pageSize={offsetData.limit}
                />

                <div className="action-wrapper" >

                    <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                        if (prevOffset >= 0) {
                            setOffsetData({ ...offsetData, offset: prevOffset })
                            setCurrentPage(currentPage - 1)
                        }
                    }}>

                        <ArrowLeftOutlined className="left-icon" />
                        {appConstants.previous}
                    </span>

                    <span className={nextOffset < (serviceListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                        if (nextOffset < (serviceListData?.pagination?.totalCount || 0)) {
                            setOffsetData({ ...offsetData, offset: nextOffset })
                            setCurrentPage(currentPage + 1)
                        }
                    }}>

                        {appConstants.next}
                        <ArrowRightOutlined className="right-icon" />

                    </span>
                </div>
            </div>

        </div>
    )
}

export default TrendingServices;