import Sider from "antd/es/layout/Sider";
import images from '../utils/images'
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { appConstants } from "../utils/appConstants";
import { useDispatch } from 'react-redux'
import { logoutAction } from '../redux/action/index'

const Sidebar = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()

    const [modalVisible, setModalVisible] = useState(false)
    const [submenuOpen, setSubmenuOpen] = useState(false)

    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)

    let activeKey = ""
    const items = [
        {
            label: "Dashboard",
            key: "/dashboard",
            icon: <img src={images.dashboardIcon} className="sidebar-menu-logo" alt="" />
        },
        {
            label: "Manage",
            key: "/user-management",
            onTitleClick: () => { setSubmenuOpen(!submenuOpen) },
            icon: <img src={images.manageIcon} className="sidebar-menu-logo" alt="" />,
            children: [
                {
                    label: "Customers",
                    key: "/customer-management",
                },
                {
                    label: "Service providers",
                    key: "/service-provider-management",
                },
                {
                    label: "Service categories",
                    key: "/service-categories",
                },
                {
                    label: "Trending services",
                    key: "/trending-services",
                }
            ],
            expandIcon: <img src={submenuOpen ? images.arrowUp : images.arrowDown} className="sidebar-collapse-icon" alt="" />,
        },
        {
            label: "Notification",
            key: "/notification-management",
            icon: <img src={images.bellIcon} className="sidebar-menu-logo" alt="" />,
        },
        {
            label: "Report",
            key: "/report-management",
            icon: <img src={images.clipboardIcon} className="sidebar-menu-logo" alt="" />
        },
        {
            label: "Reviews",
            key: "/review-management",
            icon: <img src={images.reviewIcon} className="sidebar-menu-logo" alt="" />
        },
        {
            label: "CMS Pages",
            key: "/cms-management",
            icon: <img src={images.settingIcon} className="sidebar-menu-logo" alt="" />,
        }
    ]

    switch (location.pathname) {

        case "/dashboard": activeKey = "/dashboard"
            break;

        case "/customer-management": activeKey = "/customer-management"
            break;

        case "/service-provider-management": activeKey = "/service-provider-management"
            break;

        case "/service-categories": activeKey = "/service-categories"
            break;

        case "/trending-services": activeKey = "/trending-services"
            break;

        case "/review-management": activeKey = "/review-management"
            break;

        case "/cms-management": activeKey = "/cms-management"
            break;

        case "/report-management": activeKey = "/report-management"
            break;

        case "/notification-management": activeKey = "/notification-management"
            break;

        default: activeKey = "/dashboard"
    }

    return (

        <Sider className={props.collapse ? "sidebar-wrapper" : "sidebar-wrapper sidebar-expanded"}
            collapsed={props.collapse}
            collapsedWidth="80"
        >

            <div>
                <div className="app-logo-wrapper">
                    <img src={images.appLogo} alt="" width="50px" className="app-logo" />

                </div>


                <Menu
                    className="sidebar-menu"
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={[activeKey]}
                    selectedKeys={[activeKey]}
                    items={items}
                    onClick={(e) => {
                        navigate(e.key)
                        document.getElementById('content-wrapper').scrollTop = 0

                    }}
                >

                </Menu>
            </div>

            <div className="logout-buton">

                <button onClick={() => showModal()}>

                    <img src={images.logoutIcon} className="sidebar-menu-logo" alt="" />
                    <span className="logout">{appConstants.logout}</span>

                </button>
            </div>

            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <ExclamationCircleOutlined />

                        {appConstants.logout}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        hideModal()
                        dispatch(logoutAction(navigate))
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={hideModal}>{appConstants.no}</button>

                ]}
            >
                {appConstants.logoutWarning}
            </Modal>

        </Sider>
    )
}

export default Sidebar;