import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    userListData: {},
    userListForCsv: []
};

export const customerManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // Customer list
        case apiConstants.apiCustomerListInitiate: return { ...state, loading: true };
        case apiConstants.apiCustomerListSuccess: return { ...state, loading: false, userListData: action?.result?.data };

        // Customer list
        case apiConstants.apiCustomerListForCsvInitiate: return { ...state, loading: true };
        case apiConstants.apiCustomerListForCsvSuccess: return { ...state, loading: false, userListForCsv: action?.result?.data?.list || [] };
        case apiConstants.apiCustomerListForCsvRemove: return { ...state, userListForCsv: [] };

        // Customer block
        case apiConstants.apiCustomerBlockInitiate: return { ...state, loading: true };
        case apiConstants.apiCustomerBlockSuccess: return {
            ...state, loading: false,
            userListData: {
                ...state?.userListData,
                list: JSON.parse(JSON.stringify(state?.userListData.list)).map(i => {
                    if (i._id === action?.payload?.userId) i.isBlock = !i.isBlock

                    return i
                })
            }
        };

        // Customer delete
        case apiConstants.apiCustomerDeleteInitiate: return { ...state, loading: true };
        case apiConstants.apiCustomerDeleteSuccess: return { ...state, loading: false };

        // Add Provider
        case apiConstants.apiAddProviderInitiate: return { ...state, loading: true };
        case apiConstants.apiAddProviderSuccess: return { ...state, loading: false };

        // Edit Provider
        case apiConstants.apiEditProviderInitiate: return { ...state, loading: true };
        case apiConstants.apiEditProviderSuccess: return { ...state, loading: false };

        // Customer verify
        case apiConstants.apiVerifyProviderInitiate: return { ...state, loading: true };
        case apiConstants.apiVerifyProviderSuccess: return {
            ...state, loading: false,
            userListData: {
                ...state?.userListData,
                list: JSON.parse(JSON.stringify(state?.userListData.list)).map(i => {
                    if (i._id === action?.payload?.userId) i.isVerified = action?.payload?.isVerified

                    return i
                })
            }
        };

        // Customer import
        case apiConstants.apiImportProviderInitiate: return { ...state, loading: true };
        case apiConstants.apiImportProviderSuccess: return { ...state, loading: false };

        // Failed
        case apiConstants.apiCustomerManagementFailed: return { ...state, loading: false };

        //Default case
        default: return { ...state };
    }
};
