import { apiConstants } from "../../utils/appConstants";
const initialState = {

    loading: false,
    reportListData: {unseenCategory:0,unseenFeedback:0},
};

export const reportManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // Customer list
        case apiConstants.apiReportListInitiate: return { ...state, loading: true };
        case apiConstants.apiReportListSuccess: return { ...state, loading: false, reportListData: action?.result?.data };

        // Failed
        case apiConstants.apiReportManagementFailed: return { ...state, loading: false };

        //Default case
        default: return { ...state };
    }
};
