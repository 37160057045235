import { Layout } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import images from "../utils/images";
import { useSelector, useDispatch } from 'react-redux';
import { handleSearchAction } from '../redux/action';
const { Header } = Layout;

const MainHeader = (props) => {

    const { searchValue } = useSelector(x => x.authentication)
    const dispatch = useDispatch()

    return (
        <Header className="header-wrapper">

            {
                props.search ?
                    <div className="header-search-wrapper">

                        <SearchOutlined />
                        <input
                            id="header-search"
                            type="text"
                            className="header-search"
                            placeholder={"Search"}
                            value={searchValue}
                            onChange={(e) => {

                                if (e.target.value.startsWith(' ')) e.preventDefault()
                                else dispatch(handleSearchAction({ value: e.target.value }))

                            }} /> </div>
                    :
                    <span></span>
            }

            <div className="profile-wrapper">
                <img src={images.bellIcon} alt="" className="icon" />

                <div className="profile">
                    <img src={images.dummyProfileImage} alt="" className="profile-image" />

                    <div className="name-wrapper">
                        <span className="name">
                            Johnson

                        </span>
                        <span className="position">Admin</span>
                    </div>

                    <DownOutlined />

                </div>

            </div>
        </Header>
    )
}

export default MainHeader;