import images from '../../utils/images'
import { Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '../../redux/action/index'
import { useFormik } from 'formik'
import { loginSchema } from '../../utils/validation'
import { useState, useEffect } from 'react';
import { Spin } from 'antd';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loading = useSelector(x => x.authentication.loading)
    const [remember, setRemember] = useState(false)

    const formik = useFormik({

        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: (values) => dispatch(loginAction({ email:values.email.toLocaleLowerCase(),password:values.password, deviceType: "web", deviceToken: Date.now(), remember }, navigate)),
        validationSchema: loginSchema
    })

    useEffect(() => {
        const handlePopstate = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        handlePopstate();
        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, []);


    return (
        <div className="auth-wrapper">

            <div className="auth-box">

                <div className="image-container">

                    <img src={images.loginLogo} alt="" className="logo" />

                    <div className="heading-wrapper">
                        <div className="heading margin-bottom-10">
                            Welcome to Buzz
                        </div>

                        <span className="sub-heading">
                            Sign in with Password
                        </span>
                    </div>

                    <img src={images.authFormImage} alt="" />
                </div>

                <form onSubmit={formik.handleSubmit}>

                    <div className="form-content">

                        <div className="heading">
                            Login
                        </div>

                        <span className="sub-heading margin-bottom-20">
                            Welcome! Please confirm that you are visiting
                        </span>

                        <label>Email</label>
                        <input
                            name="email"
                            onChange={(e) => {

                                if (e.target.value.startsWith(" ")) e.preventDefault()
                                else formik.handleChange(e)

                            }}
                            value={formik.values.email}
                            placeholder="Email address"
                            type="text"
                        />

                        {formik.errors.email && <div className="validation">{formik.errors.email}</div>}

                        <label>Password</label>
                        <input
                            name="password"
                            onChange={(e) => {

                                e.target.value = e.target.value.replace(/ /g, "")
                                formik.handleChange(e)

                            }}
                            value={formik.values.password}
                            placeholder="Password"
                            type="password"
                        />

                        {formik.errors.password && <div className="validation">{formik.errors.password}</div>}

                        <div className="switch-wrapper">

                            <span>
                                <Switch checked={remember} onChange={(e) => setRemember(e)} />
                                Remember me
                            </span>

                            <span className="link" onClick={() => navigate('/forgot-password')}>
                                Forgot password?
                            </span>

                        </div>

                        <div className="button-wrapper">
                            <button disabled={loading} type="submit">{loading ? <Spin /> : "Login"}</button>

                        </div>
                    </div>
                </form>

            </div>

            <div className="footer">© {moment().format("YYYY")},Buzz Plantforms INC. All Rights Reserved.</div>
        </div>
    )
}

export default Login;