import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Switch, Pagination, Select, Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";

const CMSManagement = () => {

    const [modalVisible, setModalVisible] = useState(false)
    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)

    const data = [
        {
            title: "Contact support",
            phone: "9876543219",
            blocked: false,
            key: 1,
            provider: "Anderson",
            service: "Home Cleaning"
        },
        {
            title: "Legal",
            phone: "9876543219",
            blocked: true,
            key: 2,
            provider: "Anderson",
            service: "Home Cleaning"
        },
        {
            title: "Privacy policy",
            phone: "9876543219",
            blocked: false,
            key: 3,
            provider: "Anderson",
            service: "Home Cleaning"
        },
        {
            title: "FAQs",
            phone: "9876543219",
            blocked: true,
            key: 4,
            provider: "Anderson",
            service: "Home Cleaning"
        }
    ]

    const columns = [
        {
            title: appConstants?.id,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            align: "center",
            render: (value, item, index) => index + 1,
        },
        {
            title: appConstants?.title,
            dataIndex: "title",
            key: "title",
            sorter: false,
            render: (title, data, index) => (<>{data.title || "N/A"}</>)
        },
        {
            title: appConstants?.url,
            dataIndex: "url",
            key: "url",
            sorter: false,
            render: (title, data, index) => <input className="url-input" placeholder="Link..." />
        },
        {
            title: appConstants?.order,
            dataIndex: "order",
            key: "order",
            sorter: false,
            render: (value, item, index) => 1,
        },
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            render: (value, item, index) => <div><Switch defaultChecked={item.blocked} /></div>,
        },
        {
            title: appConstants?.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.trash} alt="" onClick={() => showModal()} />

                    </div>
                );
            },
        },
    ];
    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.cmsPages}

                </div>

                <Breadcrumbs
                    path={["dashboard"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.cmsPages]}
                />
            </div>

            <Table

                className="table-wrapper"
                dataSource={data}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
            />

            <div className="pagination-wrapper">

                <div className="select-wrapper">

                    Pages :

                        <Select

                        suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                        className="select"
                        defaultValue={10}
                        options={[
                            {
                                value: 10,
                                label: '10 Rows',
                            },
                            {
                                value: 20,
                                label: '20 Rows',
                            },
                            {
                                value: 30,
                                label: '30 Rows',
                            },
                        ]}
                    />
                </div>


                <Pagination

                    className="pagination"
                    defaultCurrent={6}
                    total={20}
                />

                <div className="action-wrapper">

                    <span className="action">

                        <ArrowLeftOutlined className="left-icon" />
                        {appConstants.previous}
                    </span>

                    <span className="action">

                        {appConstants.next}
                        <ArrowRightOutlined className="right-icon" />

                    </span>
                </div>
            </div>


            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        hideModal()
                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={hideModal}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteCMSWarning}
            </Modal>

        </div>
    )
}

export default CMSManagement;