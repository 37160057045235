import { Routes, Route, Navigate } from "react-router-dom";
import Login from '../component/auth/Login'
import ForgotPassword from '../component/auth/ForgotPassword'
import ResetPassword from "../component/auth/ResetPassword";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../component/auth/Dashboard";
import VerifyOtp from '../component/auth/VerifyOtp'
import CustomerManagement from "../component/customer";
import ServiceProviderManagement from "../component/service providers";
import ServiceCategories from "../component/service categories";
import TrendingServices from "../component/trending services";
import ReviewManagement from "../component/reviews";
import CMSManagement from "../component/cms";
import ReportManagement from "../component/report";
import NotificationManagement from "../component/notification";


const Router = () => {

    const PrivateRoute = ({ component }) => localStorage.token || sessionStorage.token ? (component) : (<Navigate to="/" />);

    return (
        <>
            <Routes>

                {/* auth module */}

                <Route path="/" element={localStorage.token || sessionStorage.token ? <Navigate to={"dashboard"} /> : <Navigate to={"login"} />} />
                <Route path="/login" element={localStorage.token || sessionStorage.token ? <Navigate to={"/dashboard"} /> : <Login />} />
                <Route path="/forgot-password" element={localStorage.token || sessionStorage.token ? <Navigate to={"/dashboard"} /> : <ForgotPassword />} />
                <Route path="/verify-otp" element={localStorage.token || sessionStorage.token ? <Navigate to={"/dashboard"} /> : <VerifyOtp />} />
                <Route path="/reset-password" element={localStorage.token || sessionStorage.token ? <Navigate to={"/dashboard"} /> : <ResetPassword />} />

                {/* Private Routes */}

                <Route path="/" element={<MainLayout search={true} />}>

                    <Route path="/dashboard" element={<PrivateRoute component={<Dashboard />} />} />
                    <Route path="/customer-management" element={<PrivateRoute component={<CustomerManagement />} />} />
                    <Route path="/service-provider-management" element={<PrivateRoute component={<ServiceProviderManagement />} />} />
                    <Route path="/service-categories" element={<PrivateRoute component={<ServiceCategories />} />} />
                    <Route path="/trending-services" element={<PrivateRoute component={<TrendingServices />} />} />
                    <Route path="/review-management" element={<PrivateRoute component={<ReviewManagement />} />} />
                    <Route path="/cms-management" element={<PrivateRoute component={<CMSManagement />} />} />
                    <Route path="/report-management" element={<PrivateRoute component={<ReportManagement />} />} />
                    <Route path="/notification-management" element={<PrivateRoute component={<NotificationManagement />} />} />


                </Route>

            </Routes>
        </>
    );
};
export default Router;
