import method from "./method";

const apiFunctions = {

    loginApi: (payload) => method.post("/admin/login", payload),
    forgotPasswordApi: (payload) => method.post("/admin/forgotPassword", payload),
    verifyOtpApi: (payload) => method.post("/admin/verifyOtp", payload),
    resetPasswordApi: (payload) => method.post("/admin/resetPassword", payload),
    logoutApi: () => method.get("/admin/logout"),
    customerList: (payload) => method.post('/admin/userList', payload),
    customerBlock: (payload) => method.post('/admin/userBlock', payload),
    customerDelete: (payload) => method.post('/admin/deleteUser', payload),
    categoryList: (payload) => method.post('/admin/getCategoryList', payload),
    addProvider: (payload) => method.post('/admin/addProvider', payload, true),
    editProvider: (payload) => method.post('/admin/editProvider', payload, true),
    deleteCategory: (payload) => method.post('/admin/deleteCategory', payload),
    addCategory: (payload) => method.post('/admin/addCategory', payload, true),
    editCategory: (payload) => method.post('/admin/editCategory', payload,true),
    serviceList: (payload) => method.post('/admin/getServices', payload),
    changeTrendingServiceStatus: (payload) => method.post('/admin/changeTrendingService', payload),
    addService: (payload) => method.post('/admin/addServices', payload, true),
    deleteService: (payload) => method.post('/admin/deleteServices', payload),
    editService: (payload) => method.post('/admin/updateServices', payload,true),
    verifyProvider: (payload) => method.post('/admin/verifyUser', payload),
    dashboardApi: () => method.get('/admin/dashboard'),
    reportList: (payload) => method.post('/admin/feedbackList', payload),
    importProviderCsv: (payload) => method.post('/admin/import-provider', payload, true),
    reviewList: (payload) => method.post('/admin/ratingList', payload),
    deleteReview: (payload) => method.post('/admin/deleteReview', payload),
    blockReview: (payload) => method.post('/admin/blockReview', payload),

}

export default apiFunctions;