import { useEffect } from "react";
import * as echarts from "echarts";

const DashboardCustomerChart = ({ data }) => {


    useEffect(() => {

        const chartDom = document.getElementById('customer-chart-wrapper');
        const myChart = echarts.init(chartDom);

        const option = {

            tooltip: {
                trigger: 'item',
                show: true,
            },

            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['100%', '80%'],
                    center: ['50%', '70%'],
                    silent: true,
                    startAngle: 210,
                    label: {
                        show: false,
                    },
                    data: [
                        { value: (data?.totalCustomer / (data?.totalCustomer + data?.totalServiceProvider)) * 100 || 0, name: 'Active', itemStyle: { color: "#F4511E", borderJoin: "round" } },
                        { value: (data?.totalServiceProvider / (data?.totalCustomer + data?.totalServiceProvider)) * 100 || 0, name: 'Signout', itemStyle: { color: "#FAA477", borderJoin: "round" } },
                        {
                            value: 50,
                            itemStyle: {
                                color: 'none',
                                decal: {
                                    symbol: 'none'
                                }
                            },
                            label: {
                                show: false
                            }
                        }
                    ]
                },
            ]
        };

        option && myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [data]);

    return <div className="dashboard-charts-wrapper" id="customer-chart-wrapper" ></div>
};

export default DashboardCustomerChart;
