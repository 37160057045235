import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authentication";
import rootCustomerManagementSaga from "./customerManagement";
import rootCategoryManagementSaga from "./categoryManagement";
import rootserviceManagementSaga from "./serviceManagement";
import rootReportManagementSaga from "./reportManagement";
import rootReviewManagementSaga from "./reviewManagement";

export default function* rootSaga() {

    yield all([
        fork(rootAuthenticationSaga),
        fork(rootCustomerManagementSaga),
        fork(rootCategoryManagementSaga),
        fork(rootserviceManagementSaga),
        fork(rootReportManagementSaga),
        fork(rootReviewManagementSaga)
    ]);
}
