import authFormImage from "../images/auth_form_image.png";
import dashboardIcon from "../images/dashboard_icon.png";
import manageIcon from "../images/manage_icon.png";
import bellIcon from "../images/bell_icon.png";
import clipboardIcon from "../images/clipboard_icon.png";
import reviewIcon from "../images/review_icon.png";
import settingIcon from "../images/setting_icon.png";
import logoutIcon from "../images/logout_logo.png";
import dummyProfileImage from '../images/dummy_profile_image.png'
import chart1 from '../images/chart1.png'
import chart2 from '../images/chart2.png'
import chart3 from '../images/chart3.png'
import home from '../images/home.png'
import show from '../images/show.png'
import trash from '../images/trash.png'
import edit from '../images/edit.png'
import appLogo from '../images/app_logo.png'
import addImageIcon from '../images/add_image_icon.png'
import dummyUser from '../images/dummy_user.png'
import downArrow from '../images/down_arrow.png'
import loginLogo from '../images/login_logo.png'
import arrowUp from '../images/arrow_up.png'
import arrowDown from '../images/arrow_down.png'
import visible from '../images/visible.png'
import invisible from '../images/invisible.png'
import paginationDownIcon from '../images/pagination_down_icon.png'
import backupIcon from '../images/backup.png'


const images = {

    authFormImage,
    dashboardIcon,
    manageIcon,
    bellIcon,
    clipboardIcon,
    reviewIcon,
    settingIcon,
    logoutIcon,
    dummyProfileImage,
    chart1,
    chart2,
    chart3,
    home,
    show,
    trash,
    edit,
    appLogo,
    addImageIcon,
    dummyUser,
    downArrow,
    loginLogo,
    arrowUp,
    arrowDown,
    visible,
    invisible,
    paginationDownIcon,
    backupIcon
};

export default images;
